import React, { useReducer, useState, useContext, useEffect } from "react";
import tokens from "../Modal-Components/tokens";
import { CryptoContext } from "../CreateContext/CryptoContext";

// Define the initial state with default selected tokens
export const initialState = {
  token1: null, // Initially null until data is fetched
  token2: null, // Initially null until data is fetched
  isModalOpen: false,
  slippageOpen: false,
  slippageredopen: false,
  slippageRedText: false,
  slippageYellowOpen: false,
  slippageValue: 0.05,
  selectedTokenType: null, // To keep track of which token (token1 or token2) to update
};

export function tradeReducer(state, action) {
  switch (action.type) {
    case "SET_INITIAL_TOKENS":
      return {
        ...state,
        token1: action.payload.token1,
        token2: action.payload.token2,
      };
    case "OPEN_MODAL":
      return {
        ...state,
        isModalOpen: true,
        selectedTokenType: action.payload, // token1 or token2
      };
    case "SLIPPAGE_OPEN":
      return {
        ...state,
        slippageOpen: !state.slippageOpen,
        slippageredopen: false,
        slippageRedText: false,
        slippageYellowOpen: false,
      };
    case "SLIPPAGE_VALUE":
      return {
        ...state,
        slippageValue: action.payload,
      };
    case "SLIPPAGE_REDOPEN":
      return {
        ...state,
        slippageredopen: !state.slippageredopen,
      };
    case "SLIPPAGE_CLOSES":
      return {
        ...state,
        slippageredopen: false,
        slippageRedText: false,
        slippageYellowOpen: false,
      };
    case "SLIPPAGE_REDTEXT":
      return {
        ...state,
        slippageRedText: !state.slippageRedText,
      };
    case "SLIPPAGE_YELLOWOPEN":
      return {
        ...state,
        slippageYellowOpen: !state.slippageYellowOpen,
      };
    case "CLOSE_MODAL":
      return {
        ...state,
        isModalOpen: false,
        selectedTokenType: null,
      };
    case "SELECT_TOKEN":
      return {
        ...state,
        isModalOpen: false,
        [state.selectedTokenType]: action.payload, // Update token1 or token2
        selectedTokenType: null,
      };
    default:
      return state;
  }
}
