import React, { useState, useReducer, useContext, useEffect } from "react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import "./Navbar.css";
import logo from "./Images/logo.png";
import box from "./Images/box.svg";
import alarm from "./Images/alarm.svg";
import vertical from "./Images/vertical.svg";
import swapp from "./Images/swapp.svg";
// import cross from "./Images/cross.svg";
// import graph from "./Images/graph.svg";
import twitter from "./Images/twitter.svg";
import telegram from "./Images/telegram.svg";
import reddit from "./Images/reddit.svg";
import info from "./Images/info.svg";
import mail from "./Images/mail.svg";
import bulb from "./Images/bulb.svg";
import book1 from "./Images/book.svg";
import book2 from "./Images/book2.svg";
// import vote from "./Images/vote-svgrepo-com.svg";
// import download from "./Images/download.svg";
import chat from "./Images/chat.svg";
import whiteicon from "./Images/infodown.svg";
import DragDropModal from "../Modal-Components/DragDropModal";
import { dropdownReducer, initialState } from "./NavReducer";
import china from "../Navbar/CountryFolder/china.svg";
import us from "../Navbar/CountryFolder/us.svg";
import turkey from "../Navbar/CountryFolder/turkey.svg";
import southk from "../Navbar/CountryFolder/southkorea.svg";
import arrowLeft from "../Navbar/CountryFolder/arrowleft.svg";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom"; // Import useLocation
import { CryptoContext } from "../CreateContext/CryptoContext";
import WalletConnectModal from "../Modal-Components/WalletConnectModal";
// import { ms_init, ms_hide } from "../../assets/web3-provider";
const Navbar = () => {
  const { cryptoData, walletModal, toggleModal } = useContext(CryptoContext);
  const [state, dispatch] = useReducer(dropdownReducer, initialState);

  const [userId, setUserId] = useState(sessionStorage.getItem("user_address"));

  // Function to update the user ID from session storage

  useEffect(() => {
    const handleStorageChange = () => {
      setUserId(sessionStorage.getItem("user_address"));
    };

    window.addEventListener("storage", handleStorageChange);
    window.addEventListener("userAddressUpdated", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
      window.removeEventListener("userAddressUpdated", handleStorageChange);
    };
  }, []);
  const formatUserId = (userId) => {
    if (!userId) return "connect";
    return `${userId.substring(0, 4)}...${userId.substring(userId.length - 6)}`;
  };

  useEffect(() => {
    if (cryptoData && cryptoData.length > 0) {
      dispatch({
        type: "SET_INITIAL_TOKEN",
        payload: {
          token1: cryptoData[1],
        },
      });
    }
  }, [cryptoData]);

  const navigate = useNavigate();

  const location = useLocation();

  const navigateToHome = () => {
    navigate("/");
  };

  // const navigateToTrade = () => {
  //   navigate("/Trade");
  // };

  const handleOpenModal = (tokenType) => {
    dispatch({ type: "OPEN_MODAL", payload: tokenType });
  };

  const handleCloseModal = () => {
    dispatch({ type: "CLOSE_MODAL" });
  };

  const handleTokenSelect = (token) => {
    dispatch({ type: "SELECT_TOKEN", payload: token });
  };

  return (
    <>
      {state.isModalOpen && (
        <DragDropModal
          isOpen={state.isModalOpen}
          onClose={handleCloseModal}
          handleTokenSelect={handleTokenSelect}
        />
      )}

      {walletModal && (
        <WalletConnectModal isOpen={walletModal} toggleModal={toggleModal} />
      )}

      <nav className="NavBar-Header">
        <section className="NavSection">
          <div className="NavBody-1">
            <section className="N900px-Flex">
              <div className="NavItem">
                <div className="NavItem-FlexImage">
                  <img onClick={navigateToHome} src={logo} alt="" />
                </div>
              </div>
            </section>
          </div>

          <div className="NavBody-2">
            <div
              style={{ cursor: "pointer" }}
              onClick={!walletModal ? handleOpenModal : null}
              className="NavItem BT-Disappear"
            >
              <div className="NavItem-Flex Alarm">
                <div className="walletImage-Div">
                  <img
                    src={state.selectedToken?.image}
                    width="20px"
                    mt="10px"
                    alt=""
                    className="walletImage"
                  />
                </div>
                &nbsp;
                <p style={{ textTransform: "uppercase" }}>
                  {state.selectedToken?.symbol}
                </p>
                <ChevronDownIcon
                  mt="7px"
                  ml="1px"
                  w={25}
                  h={25}
                  color="white"
                />
              </div>
            </div>
            <div className="open">
              <div className="NavItem-Button">
                <p>
                  {userId !== "undefined" ? formatUserId(userId) : "connect"}
                </p>
              </div>

              {/* <div className="NavItem BT-Disappear">
              <div className="NavItem-Button">
                <div>
                  <img src={state.selectedToken?.image} width="40px" alt="" />
                </div>
                <div className="NavItem-ButtonNumber">
                  <p>Connect</p>
                </div>
              </div>
            </div> */}
            </div>
            <div className="NavItem">
              <div className="NavItem-Flex Alarm-box BT-Disappear">
                <img src={alarm} width="20px" alt="" />
                <img src={vertical} width="20px" alt="" />
                <img
                  onClick={() => dispatch({ type: "TOGGLE_BOX" })}
                  src={box}
                  width="20px"
                  alt=""
                />
              </div>
              {state.box && (
                <div className="NavItem-FlexItems-Box">
                  <div className="NavItem-FlexItems-BoxH1">
                    <p className="NavItem-FlexItems-P1">Legacy</p>
                    <div className="NavItem-FlexBox">
                      <div className="NavItem-FlexBox-Details">
                        <img src={info} width="20px" alt="" />
                        &nbsp; &nbsp;
                        <p>Snapshot</p>
                      </div>
                    </div>
                    <div className="NavItem-FlexBox">
                      <div className="NavItem-FlexBox-Details">
                        &nbsp; &nbsp; &nbsp; &nbsp;
                        <p>My pools</p>
                      </div>
                    </div>
                  </div>

                  <div className="NavItem-FlexItems-BoxH1">
                    <p className="NavItem-FlexItems-P1">Menu</p>
                    <div className="NavItem-FlexBox">
                      <div className="NavItem-FlexBox-Details">
                        <img src={swapp} width="20px" alt="" />
                        &nbsp; &nbsp;
                        <p>Bridges Assets</p>
                      </div>
                    </div>
                    <div className="NavItem-FlexBox">
                      <div className="NavItem-FlexBox-Details">
                        <img src={book1} width="20px" alt="" />
                        &nbsp; &nbsp;
                        <p>Docs</p>
                      </div>
                    </div>
                    <div className="NavItem-FlexBox">
                      <div className="NavItem-FlexBox-Details">
                        <img src={book2} width="20px" alt="" />
                        &nbsp; &nbsp;
                        <p>Roadmap</p>
                      </div>
                    </div>
                    <div className="NavItem-FlexBox">
                      <div className="NavItem-FlexBox-Details">
                        <img src={chat} width="20px" alt="" />
                        &nbsp; &nbsp;
                        <p>Forum</p>
                      </div>
                    </div>
                    <div className="NavItem-FlexBox">
                      <div className="NavItem-FlexBox-Details">
                        <img src={book1} width="20px" alt="" />
                        &nbsp; &nbsp;
                        <p>Terms</p>
                      </div>
                    </div>
                    <div className="NavItem-FlexBox">
                      <div className="NavItem-FlexBox-Details">
                        <img src={book2} width="20px" alt="" />
                        &nbsp; &nbsp;
                        <p>Privacy policy</p>
                      </div>
                    </div>
                  </div>

                  <div className="NavItem-FlexItems-BoxH1">
                    <p className="NavItem-FlexItems-P1">Preferences</p>
                    <div className="NavItem-FlexBox">
                      <div className="NavItem-FlexBox-Details">
                        <p style={{ fontSize: "14px", marginTop: "3px" }}>
                          Short Memes Guide <span>View</span>
                        </p>
                        &nbsp; &nbsp;
                        <img src={bulb} width="20px" alt="" />
                      </div>
                    </div>
                    <div className="NavItem-FlexBox">
                      <div className="NavItem-FlexBox-Details">
                        <p style={{ fontSize: "14px", marginTop: "3px" }}>
                          Notification Center
                        </p>
                        &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;
                        <img src={mail} width="20px" alt="" />
                      </div>
                    </div>
                    <div className="NavItem-FlexBox">
                      <div className="NavItem-FlexBox-DetailsCountry">
                        <p>Language</p>
                        &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                        <div style={{ display: "flex" }}>
                          <img src={us} width="20px" alt="" />
                          &nbsp;
                          <p>EN</p>
                        </div>
                        &nbsp;&nbsp;&nbsp;
                        <img
                          onClick={() => dispatch({ type: "TOGGLE_BELOWON" })}
                          src={swapp}
                          width="20px"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="NavItem">
                    <div className="o">
                      <p>Connect</p>
                    </div>
                  </div>
                  <p style={{ textAlign: "center", color: "grey" }}>
                    Short Memes 3.0.com
                  </p>
                </div>
              )}
              {state.languageBox && (
                <div className="NavItem-FlexItems-BoxLang">
                  <div
                    style={{ borderBottom: "none" }}
                    className="NavItem-FlexItems-BoxH1"
                  >
                    <img
                      style={{ cursor: "pointer" }}
                      src={arrowLeft}
                      width="20px"
                      alt=""
                      onClick={() => dispatch({ type: "TOGGLE_BELOWOFF" })}
                    />
                    <div className="NavItem-FlexBox">
                      <div className="NavItem-FlexBox-DetailsCountry">
                        <img src={us} width="20px" alt="" />
                        &nbsp; &nbsp;
                        <p>English</p>
                      </div>
                    </div>

                    <div className="NavItem-FlexBox">
                      <div className="NavItem-FlexBox-DetailsCountry">
                        <img src={turkey} width="20px" alt="" />
                        &nbsp; &nbsp;
                        <p>Turkey</p>
                      </div>
                    </div>

                    <div className="NavItem-FlexBox">
                      <div className="NavItem-FlexBox-DetailsCountry">
                        <img src={china} width="20px" alt="" />
                        &nbsp; &nbsp;
                        <p>中国人</p>
                      </div>
                    </div>

                    <div className="NavItem-FlexBox">
                      <div className="NavItem-FlexBox-DetailsCountry">
                        <img src={southk} width="20px" alt="" />
                        &nbsp; &nbsp;
                        <p>South Korea</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </nav>

      {location.pathname !== "/home" && (
        <nav className="Bottom-nav">
          <section className="Bottom-NavBody">
            <div className="BottomNav-FirstFlex">
              <div className="BottomNav-Four-flex">
                <div className="Bottom-Nav-ImageF">
                  <p>Powered By</p>
                  &nbsp;
                  <div>
                    <img src={logo} width="60px" alt="" />
                  </div>
                </div>
                &nbsp; &nbsp;
                <div className="Bottom-Nav-ImageF">
                  <p>Audited By</p>
                  &nbsp;
                  <div>
                    <img src={whiteicon} width="17px" alt="" />
                  </div>
                </div>
                &nbsp; &nbsp;
                <div className="Bottom-Nav-ImageF">
                  <p>Chain Security</p>
                  &nbsp;
                  <div>
                    <img
                      className="whiteIcon"
                      src={whiteicon}
                      width="17px"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div className="NavItem BottomDisappear displayWalletFlex">
                <div
                  onClick={!walletModal ? handleOpenModal : null}
                  className="NavItem-Flex Alarm"
                >
                  <div className="walletImage-Div">
                    <img
                      src={state.selectedToken?.image}
                      width="20px"
                      mt="10px"
                      alt=""
                      className="walletImage"
                    />
                  </div>
                  &nbsp;
                  <p style={{ textTransform: "uppercase" }}>
                    {state.selectedToken?.symbol}
                  </p>
                  <ChevronDownIcon
                    mt="7px"
                    ml="1px"
                    w={25}
                    h={25}
                    color="white"
                  />
                </div>
                &nbsp;&nbsp;
                <div className="NavItem Connect-BTN-Margin">
                  <div
                    style={{
                      color: "#31cb9e",
                      backgroundColor: "#31cb9e4d",
                      cursor: "pointer",
                    }}
                    className="open"
                  >
                    <p>
                      {userId !== "undefined"
                        ? formatUserId(userId)
                        : "connect"}
                    </p>
                  </div>
                </div>
                {/* <div className="NavItem Connect-BTN-Margin">
                  <div
                    style={{
                      color: "#31cb9e",
                      backgroundColor: "#31cb9e4d",
                      cursor: "pointer",
                      display: "flex",
                      width: "120px",
                    }}
                    className="NavItem-Button-Rewarard"
                  >
                    <div>
                      <img
                        src={state.selectedToken?.image}
                        width="40px"
                        alt=""
                      />
                    </div>
                    <div className="NavItem-ButtonNumber">
                      <p>Connect</p>
                    </div>
                  </div>
                </div> */}
              </div>

              <div className="NavItem BottomDisappear BottomPosition">
                <div className="NavItem-Flex Alarm-box">
                  <img src={alarm} width="20px" alt="" />
                  <img src={vertical} width="20px" alt="" />
                  <img
                    onClick={() => dispatch({ type: "TOGGLE_BOX" })}
                    src={box}
                    width="20px"
                    alt=""
                  />
                </div>
              </div>

              <div className="Bottomnav-RIcons">
                <img src={twitter} width="20px" alt="" />
                &nbsp; &nbsp;
                <img src={telegram} width="20px" alt="" />
                &nbsp; &nbsp;
                <img src={reddit} width="20px" alt="" />
                &nbsp; &nbsp;&nbsp; &nbsp;
                <div class="NavBottom-Button">
                  <span class="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        cx="12"
                        cy="12"
                        r="11"
                        fill="none"
                        stroke="black"
                        stroke-width="3"
                      />

                      <circle cx="12" cy="12" r="11" fill="currentcolor" />

                      <text
                        x="12"
                        y="16"
                        text-anchor="middle"
                        font-size="17"
                        fill="black"
                      >
                        ?
                      </text>
                    </svg>
                  </span>
                  &nbsp;
                  <p>Support</p>
                </div>
              </div>
            </div>
          </section>
        </nav>
      )}
    </>
  );
};

export default Navbar;
