import React from "react";
// import { useDisclosure } from "@chakra-ui/react";
// import { CloseIcon } from "@chakra-ui/icons";
// import { Modal, ModalOverlay, ModalContent } from "@chakra-ui/react";
// import "./WalletConnectModal.css";
// import * as Images from "../Modal-Components/TokenImages";
const WalletConnectModal = ({ isOpen, onClose }) => {
  //   const [selectedWallet, setSelectedWallet] = useState("");

  //   const handleWalletMetaMask = () => {
  //     setSelectedWallet("MetaMask");
  //     connect_wallet("MetaMask");
  //   };
  //   const handleWalletBinance = () => {
  //     setSelectedWallet("Binance Wallet");
  //     connect_wallet("Binance Wallet");
  //   };
  //   const handleWalletTrust = () => {
  //     setSelectedWallet("Trust Wallet");
  //     connect_wallet("Trust Wallet");
  //   };
  //   const handleWalletCoin = () => {
  //     setSelectedWallet("Coinbase");
  //     connect_wallet("Coinbase");
  //   };

  //   const handleWalletConnect = () => {
  //     setSelectedWallet("WalletConnect");
  //     use_wc();
  //   };

  //Noteeeeee, some of the styling,
  //starting ms is in the web3 - modal.js file, it can be called here, like the hover color for ms_modal_el is there in line 4 almost at the end.
  //so advice in editing the styling, just search for the class name in the ms styles and locate where they called it. only for this modall
  return (
    <>
      <div>hello</div>
      {/*       
      <Modal isOpen={isOpen} onClose={onClose} size="md">
        <ModalOverlay className="custom-modal-overlay" />
        <ModalContent className="WalletConnectModal">
          <div className="ms_modal_content">
            <div className="ms_modal_head">
              <a
                href="https://ethereum.org/en/wallets/find-wallet/"
                target="_blank"
                rel="noreferrer"
                aria-label="Close"
                className="bt-left"
              >
                <svg
                  aria-hidden="true"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 11C20 15.9706 15.9706 20 11 20C6.02944 20 2 15.9706 2 11C2 6.02944 6.02944 2 11 2C15.9706 2 20 6.02944 20 11ZM22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11ZM11.6445 12.7051C11.6445 13.1348 11.3223 13.4678 10.7744 13.4678C10.2266 13.4678 9.92578 13.1885 9.92578 12.6191V12.4795C9.92578 11.4268 10.4951 10.8574 11.2686 10.3203C12.2031 9.67578 12.665 9.32129 12.665 8.59082C12.665 7.76367 12.0205 7.21582 11.043 7.21582C10.3232 7.21582 9.80762 7.57031 9.45312 8.16113C9.38282 8.24242 9.32286 8.32101 9.2667 8.39461C9.04826 8.68087 8.88747 8.8916 8.40039 8.8916C8.0459 8.8916 7.66992 8.62305 7.66992 8.15039C7.66992 7.96777 7.70215 7.7959 7.75586 7.61328C8.05664 6.625 9.27051 5.75488 11.1182 5.75488C12.9336 5.75488 14.5234 6.71094 14.5234 8.50488C14.5234 9.7832 13.7822 10.417 12.7402 11.1045C11.999 11.5986 11.6445 11.9746 11.6445 12.5762V12.7051ZM11.9131 15.5625C11.9131 16.1855 11.376 16.6797 10.7529 16.6797C10.1299 16.6797 9.59277 16.1748 9.59277 15.5625C9.59277 14.9395 10.1191 14.4453 10.7529 14.4453C11.3867 14.4453 11.9131 14.9287 11.9131 15.5625Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </a>
              <title>
                <p
                  style={{
                    marginTop: "16px",
                    color: "white",
                    fontSize: "20px",
                  }}
                >
                  Connect wallet
                </p>
              </title>
              <button onClick={onClose} aria-label="Close" className="bt-right">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 13L13 1"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                  ></path>
                  <path
                    d="M1 0.999999L13 13"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                  ></path>
                </svg>
              </button>
            </div>
            <div className="ms_modal_menu">
              <div className="ms_modal_menu_first">
                <div
                  className={`ms_modal_el ${
                    selectedWallet === "MetaMask" ? "selected" : ""
                  }`}
                  onClick={handleWalletMetaMask}
                >
                  <div className="ms_modal_el-content">
                    <img src={Images.metamask} width="32px" alt="binance" />
                    <div className="ms_modal_el-title">
                      <div className="ms_modal_el-title-first">MetaMask</div>
                    </div>
                  </div>
                  <input
                    type="radio"
                    id="ms_wallet_mm"
                    name="ms_wallet"
                    checked={selectedWallet === "MetaMask"}
                    readOnly
                  />
                </div>
                <div
                  className={`ms_modal_el ${
                    selectedWallet === "Coinbase" ? "selected" : ""
                  }`}
                  onClick={handleWalletCoin}
                >
                  <div className="ms_modal_el-content">
                    <img src={Images.coinbase} width="32px" alt="binance" />
                    <div className="ms_modal_el-title">
                      <div className="ms_modal_el-title-first">Coinbase</div>
                    </div>
                  </div>
                  <input
                    type="radio"
                    id="ms_wallet_mm"
                    name="ms_wallet"
                    checked={selectedWallet === "Coinbase"}
                    readOnly
                  />
                </div>

                <div
                  className={`ms_modal_el ${
                    selectedWallet === "Trust Wallet" ? "selected" : ""
                  }`}
                  onClick={handleWalletTrust}
                >
                  <div className="ms_modal_el-content">
                    <img src={Images.trustwallet} width="32px" alt="binance" />
                    <div className="ms_modal_el-title">
                      <div className="ms_modal_el-title-first">
                        Trust Wallet
                      </div>
                    </div>
                  </div>
                  <input
                    type="radio"
                    id="ms_wallet_mm"
                    name="ms_wallet"
                    checked={selectedWallet === "Trust Wallet"}
                    readOnly
                  />
                </div>

                <div
                  className={`ms_modal_el ${
                    selectedWallet === "WalletConnect" ? "selected" : ""
                  }`}
                  onClick={handleWalletConnect}
                >
                  <div className="ms_modal_el-content">
                    <img src={Images.wc} width="32px" alt="walletconnect" />
                    <div className="ms_modal_el-title">
                      <div className="ms_modal_el-title-first">
                        WalletConnect Mobile
                      </div>
                    </div>
                  </div>
                  <input
                    type="radio"
                    id="ms_wallet_wc"
                    name="ms_wallet"
                    checked={selectedWallet === "WalletConnect"}
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
        </ModalContent>
      </Modal> */}
    </>
  );
};

export default WalletConnectModal;
